<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <material-card color="primary" title="Edit Profile" text="Complete your profile">
          <v-form ref="form" @submit.prevent="validateBeforeSubmit">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="First Name"
                    v-model="firstName"
                    v-validate="'required'"
                    data-vv-as="firstName"
                    name="firstName"
                    :error-messages="errors.collect('firstName')"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="Last Name"
                    v-model="lastName"
                    v-validate="'required'"
                    data-vv-as="lastName"
                    name="lastName"
                    :error-messages="errors.collect('lastName')"
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <div class="v-text-field__slot">
                    <label class="v-label" style="font-size: 75%;">My Roles</label>
                  </div>
                  <ul>
                    <li v-for="(role, i) in user.roles" :key="i">{{role}}</li>
                  </ul>
                </v-col>
                <v-col cols="12">
                   <address-picker
                      :detail-mode="false"
                      label="Address"
                      :min-characters="4"
                      v-model="address"
                      required
                      vvalidate="'required'"
                      data-vv-as="Address"
                      name="address"
                      :error-messages="errors.collect('address')"
                    ></address-picker>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="startDateString"
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="startDateString"
                        label="Start date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        v-validate="'required'"
                        data-vv-as="Start date"
                        name="startDateString"
                        :error-messages="errors.collect('startDateString')"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDateString"
                      no-title
                      scrollable
                      color="green lighten-1"
                      dark
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.startDateMenu.save(startDateString)"
                      >OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-radio-group v-model="isPostalTheSame" label="Is your Postal Address the same?">
                    <v-radio label="Yes" v-bind:value="true"></v-radio>
                    <v-radio label="No" v-bind:value="false"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" v-if="isPostalTheSame == false">
                  <address-picker
                    :detail-mode="false"
                    label="Postal address"
                    :min-characters="4"
                    v-model="postalAddress"
                    :required="true"
                    v-validate="'required'"
                    data-vv-as="postalAddress"
                    name="postalAddress"
                    :error-messages="errors.collect('postalAddress')"
                  ></address-picker>
                </v-col>
                <v-col cols="12">
                  <v-textarea filled v-model="qualifications" 
                  label="Qualifications"
                  v-validate="'required'"
                  data-vv-as="qualifications"
                  name="qualifications"
                  :error-messages="errors.collect('qualifications')"
                  ></v-textarea>
                </v-col>
                
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" type="submit">Save</v-btn>
            </v-card-actions>
          </v-form>
        </material-card>
      </v-col>
      <v-col cols="12" md="4">
        <material-card class="v-card-profile justify-center">
          <v-card-text class="text-center">
            <v-avatar size="150" v-if="user!=null &&user.photoURL">
              <v-img v-bind:src="user.photoURL"></v-img>
            </v-avatar>
            <v-icon size="60" color="tertiary" v-if="user==null || !user.photoURL">mdi-account</v-icon>
            <br />
            <br />
            <h6 class="category text-gray font-weight-thin mb-3">{{user.email}}</h6>
            <h4 class="card-title font-weight-light">{{user.displayName}}</h4>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import AddressPicker from "../components/core/AddressPicker";
//import PlacesView from "../components/core/PlacesView";

Vue.use(VeeValidate);
export default {
  components: {
    AddressPicker,
    //PlacesView,
     },
  
  data() {
    return {
      firstName: "",
      lastName: "",
      address: "",
      postalAddress: "",
      isPostalTheSame: false,
      qualifications: "",
      startDateMenu: false,
      startDateString: ""
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/");
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.address = this.user.address;
      
      this.postalAddress = this.user.postalAddress;
      this.isPostalTheSame = this.user.isPostalTheSame;
      this.qualifications = this.user.qualifications;
      this.startDateString = this.user.startDateString;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let me = this;
          this.user.firstName = this.firstName;
          this.user.lastName = this.lastName;
          this.user.displayName = `${this.firstName} ${this.lastName}`;
          this.user.address = this.address;
          this.user.postalAddress = this.$utils.emptyThen(this.postalAddress,"");
          this.user.isPostalTheSame =  this.$utils.emptyThen(this.isPostalTheSame,true);
          this.user.qualifications = this.qualifications;
          this.user.startDateString = this.startDateString;

          me.$store.dispatch("saveUser", this.user);

          this.$store.dispatch("setToast", {
            message: "Profile saved",
            color: "primary"
          });
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    onSignin() {
      this.$store.dispatch("signUserIn", {
        email: this.email,
        password: this.password
      });
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    }
  }
};
</script>
